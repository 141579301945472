import { GetServerSideProps } from 'next'
import getConfig from 'next/config'
import Layout from '../components/layouts/Layout'
import LoginForm, { LoginData } from '../components/auth/LoginForm'
import { useState } from 'react'
import FooterLinks from '../components/footer/FooterLinks'
import indexStyles from '../styles/modules/Index.module.scss'
import Cookies from 'cookies'
import { ORIGIN_KEY, TOKEN_KEY, USER_KEY } from '../helpers/constants'
import { ParsedUrlQuery } from 'querystring'

const signin = async (email: string, password: string): Promise<any> => {
  const response = await fetch(`/api/v1/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      email,
      password,
    }),
  })

  const data = await response.json()
  return data
}

interface LoginProps {
  errorMessage?: string
  redirect: Optional<string>
  query: ParsedUrlQuery
}

const addSearchParamsToUrl = (url: string, params: Record<string, string>) => {
  try {
    const urlObj = new URL(url)
    for (const [key, value] of Object.entries(params)) {
      urlObj.searchParams.set(key, value)
    }
    return urlObj.toString()
  } catch (e) {
    return url
  }
}

// For login check iout this
// https://www.youtube.com/watch?v=yPBtVxDEC0w

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { error, redirect, ...query } = context.query || {}

  const cookies = new Cookies(context.req, context.res, { secure: true })
  const token = cookies.get(TOKEN_KEY) ?? ''
  const origin = cookies.get(ORIGIN_KEY) ?? ''

  const cookiesOpts = {
    httpOnly: true,
    maxAge: -1,
    secure: true,
    sameSite: 'lax' as 'lax',
  }

  if (token && origin) {
    const {
      publicRuntimeConfig: { serverName },
    } = getConfig()
    const graphqlResp = await fetch(`https://${serverName}/v2/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'pong-origin': origin,
      },
      credentials: 'include',
      body: JSON.stringify({
        operationName: 'MeQuery',
        query: `query MeQuery {
          me {
            identity {
              displayName
            }
          }
        }`,
        variables: {},
      }),
    })

    if (graphqlResp.status === 403) {
      cookies.set(TOKEN_KEY, null, cookiesOpts)
      cookies.set(ORIGIN_KEY, null, cookiesOpts)
      cookies.set(USER_KEY, null, cookiesOpts)
    } else {
      const { data } = await graphqlResp.json()
      if (data.me?.identity) {
        const destination = addSearchParamsToUrl(String(redirect), {
          jwt: token,
          ...query,
        })
        return {
          redirect: {
            destination,
            permanent: false,
          },
        }
      }
    }
  }

  let errorMessage = ''
  if (error === 'token_expired') {
    errorMessage =
      'The link used to reset your password is invalid or has expired. Please request a new one.'
  }

  return {
    props: {
      errorMessage,
      redirect: redirect || null,
      query,
    },
  }
}

export default function Login(props: LoginProps) {
  const [loginError, setLoginError] = useState(props.errorMessage)

  const requestLogin = async (data: LoginData) => {
    try {
      const result = await signin(data.email, data.password)
      if (!result.error) {
        const destination = addSearchParamsToUrl(String(props.redirect), {
          jwt: result.token,
          ...props.query,
        })
        window.location.href = destination
      } else {
        setLoginError(result.error)
      }
    } catch (error) {
      setLoginError('Login failed.')
    }
  }

  return (
    <Layout>
      <div className={`${indexStyles.container} card-container get-support`}>
        <div className={`${indexStyles.content} track-request`}>
          <h1 id="trackrequests" data-testid="heading-title">
            Log In
          </h1>
          <LoginForm
            loginError={loginError}
            loginRequest={requestLogin}
            redirect={props.redirect}
          />
        </div>
      </div>
      <FooterLinks />
    </Layout>
  )
}
