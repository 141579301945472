import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import formStyles from '../../styles/modules/Form.module.scss'

export interface LoginData {
  email: string
  password: string
  savelogin: boolean
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  savelogin: yup.boolean(),
})

// Move this into a helper function
const getLogin = () => {
  let storedEmail = ''
  if (typeof window !== 'undefined' && window.localStorage) {
    const storage = window.localStorage
    storedEmail = storage.getItem('remember') || ''
  }
  return storedEmail
}

const saveLogin = (email: string) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storage = window.localStorage
    storage.setItem('remember', email)
  }
}

const clearLogin = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storage = window.localStorage
    storage.removeItem('remember')
  }
}

interface LoginProps {
  loginRequest: (data: LoginData) => void
  loginError?: string
  redirect: Optional<string>
}

const Login: React.FC<LoginProps> = (props) => {
  const [showpwd, setShowpwd] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const showPasswordToggle = () => {
    setShowpwd(!showpwd)
  }

  const queryString = props.redirect ? `?redirect=${props.redirect}` : ''

  const defaultValues = {
    email: getLogin() || '',
    password: '',
    savelogin: true,
  }

  const { register, handleSubmit, formState, clearErrors, watch } =
    useForm<LoginData>({
      resolver: yupResolver(schema),
      defaultValues,
      mode: 'onChange',
    })

  const errors = formState.errors

  const onSubmit = async (data: LoginData) => {
    setShowErrors(true)
    if (data.savelogin) {
      saveLogin(data.email)
    } else {
      clearLogin()
    }
    props.loginRequest(data)
  }

  const emailField = watch('email')
  const passwordField = watch('password')
  const onBlur = () => {
    if (
      emailField === defaultValues.email &&
      passwordField === defaultValues.password
    ) {
      clearErrors(['email', 'password'])
    }
    schema
      .validate({
        email: emailField,
      })
      .catch(() => {
        setShowErrors(true)
      })
  }

  const onFocus = () => {
    if (
      emailField === defaultValues.email &&
      passwordField === defaultValues.password
    ) {
      clearErrors(['email', 'password'])
    }
    setShowErrors(false)
    schema
      .validate({
        email: emailField,
      })
      .catch(() => {
        setShowErrors(true)
      })
  }

  return (
    <section className={`${formStyles.login}`}>
      <div className="d-flex flex-column content">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${formStyles['auth-container']}`}
          autoComplete="off"
        >
          <div className="form-group">
            <label
              htmlFor="email"
              className="form-label"
              data-testid="label-email"
            >
              Email Address
            </label>
            <div className="form-group__fields">
              <input type="hidden" autoComplete="off" />
              <input
                type="email"
                className={`form-control auth-input ${
                  errors.email && showErrors ? 'is-invalid' : ''
                }`}
                {...register('email')}
                defaultValue=""
                autoComplete="off"
                onBlur={onBlur}
                onFocus={() => setShowErrors(false)}
                data-testid="email"
              />
            </div>
            {errors.email && showErrors && (
              <span data-testid="error-email" className="form-error">
                {errors.email.message}
              </span>
            )}
          </div>

          <div className="form-group mt-4">
            <label
              htmlFor="password"
              className="form-label"
              data-testid="label-password"
            >
              Password
            </label>
            <span
              data-testid="password-toggle"
              className="password-toggle"
              onClick={showPasswordToggle}
            >
              <i className={`fa ${showpwd ? 'fa-eye-slash' : 'fa-eye'} `} />
              <span className="password-toggle-label">
                {showpwd ? 'Hide' : 'Show'}
              </span>
            </span>
            <div className="form-group__fields">
              <input
                type={showpwd ? 'text' : 'password'}
                className="form-control auth-input auth-pwd"
                defaultValue=""
                autoComplete="off"
                {...register('password')}
                onBlur={onBlur}
                onFocus={onFocus}
                data-testid="password"
              />
            </div>
            {errors.password && showErrors && (
              <span data-testid="password-error" className="form-error">
                {errors.password.message}
              </span>
            )}
            <div className="auth-message-container">
              {props.loginError && showErrors && (
                <span
                  className="form-group__fields--error"
                  data-testid="login-error"
                >
                  {props.loginError}
                </span>
              )}
            </div>
          </div>

          <div className={`${formStyles['form-actions']}`}>
            <button
              data-testid="btn-signin"
              type="submit"
              className="btn btn-purple auth-button mb-3"
              disabled={!formState.isValid}
            >
              Sign In
            </button>

            <div className={`${formStyles['save-user']}`}>
              <input
                type="checkbox"
                {...register('savelogin')}
                data-testid="save-login"
              />
              <label data-testid="label-save-login" htmlFor="save-login">
                Save my info for future log-ins
              </label>
            </div>

            <div className={`${formStyles['links']}`}>
              <a
                href={`/iforgot${queryString}`}
                data-testid="link-forgotpwd"
                className={`${formStyles['text-link-children']}`}
              >
                Reset Password
              </a>
              <a
                href={`/request-account${queryString}`}
                data-testid="link-request-account"
                className={`${formStyles['text-link-children']}`}
              >
                Request an Account
              </a>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Login
